import React from "react"
import { Link } from "gatsby"
import { Row, Col, Card } from "react-bootstrap"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { FormEventAnalytics } from "../../utils/analytics"
import { setUTMSource } from "../../utils/storage"
import scrollTo from "gatsby-plugin-smoothscroll"

class TestCenter extends React.Component {
  componentDidMount() {
    setUTMSource()
  }

  render() {
    return (
      <Layout>
        <SEO
          title={"Test Center"}
          titleDesc={
            "Fuse Classroom, an all encompassing platform that enables test centers to create, offer, and proctor online exams all in one place."
          }
          titleKeywords={
            "online exams, plagiarism detection, proctoring tools, online test,online examination system, proctor online exams, online exam tool, online proctoring tools"
          }
          fbDes={"Fuse Classroom enables test centers to create, offer, and proctor online exams."}
          twitDesc={"Fuse Classroom enables test centers to create, offer, and proctor online exams."}
          seoLink={"solutions/test-center/"}
        />

        {/* top section */}
        <section role="top--section" className="bg-light-green">
          {/* content */}
          <article className="container">
            <Row className="align-items-center py-4">
              <Col md="6" className="pt-md-5 pb-5">
                <div className="text-green">
                  <h1>
                    Fuse Classroom for <br className="d-none d-lg-block" /> Test Centers
                  </h1>
                  <div className="h4 mt-4 text-black font-weight-normal">
                    Fuse Classroom is an all encompassing platform built so instructors can create, offer, and proctor
                    online exams all in one place.
                  </div>

                  <div className="main-cta mt-4">
                    <Link
                      to="/request-for-more-info/"
                      className="btn btn-success mr-3 btn-lg font-weight-semibold"
                      // onClick={() => FormEventAnalytics("TestCenter_Top_Request_Demo")}
                      state={{ page: "testcenter" }}
                      id="request--for-more-info__top"
                    >
                      REQUEST FOR MORE INFO
                    </Link>
                  </div>
                </div>
              </Col>

              <Col sm={{ spans: 5, offset: 1 }}>
                <img
                  src="/images/solutions/test/test-center.webp"
                  alt="Test Center"
                  className="shadow-lg img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
            </Row>
          </article>
        </section>
        {/* top section end */}

        {/* Artificial Intelligence */}
        <section className="pb-5 d-none d-md-block" id="ai-info">
          <article className="container ai-features sm-2">
            <Row>
              <Col sm="12" className="py-4 mb-5 text-center">
                <h2 className="text-center ai-features-title" onClick={() => scrollTo("#AI-Assisted-Learning")}>
                  Artificial <br /> Intelligence
                </h2>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Seamless-Online-Exams")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/instructor.svg" alt="Online Exams" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">Online Exams</Card.Title>
                      <div className="small">Students can study with digital flashcards and practice exams.</div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Proctoring-Tools")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/proctoring-tools.svg" alt="Proctoring Tools" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">Proctoring Tools</Card.Title>
                      <div className="small">
                        This feature ensures that online exams are protected from cheating of any kind.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#AI-Exam-Creator")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img
                          src="/images/icon/exam-creator.svg"
                          alt="AI Exam Builder"
                          className="img-fluid"
                          width="45"
                          height="auto"
                        />
                      </div>
                      <Card.Title className="ai-title">AI Exam Builder</Card.Title>
                      <div className="small">
                        Creates exams from scratch and allows you to import exams from third party databases.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#AI-Quiz-Difficulty-Prediction")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img
                          src="/images/icon/difficulty-prediction.svg"
                          alt="AI Assessment Difficulty Prediction"
                          width="45"
                          height="auto"
                        />
                      </div>
                      <Card.Title className="ai-title">AI Assessment Difficulty Prediction</Card.Title>
                      <div className="small">Helps instructors create exams with consistent levels of difficulty.</div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>
            </Row>
          </article>
        </section>
        {/* Artificial Intelligence End */}

        {/* AI Assisted Learning */}
        <section className="py-5 bg-green" id="AI-Assisted-Learning">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 8, offset: 2 }}>
                <div className="my-4 text-center">
                  <h2 className="my-4 title text-white">AI Assisted Learning</h2>
                  <div className="h4 text-white font-weight-normal">
                    Embedded in Fuse Classroom are AI features including Student Status, Activity Recommendation,
                    Automated Discussion Forums, Question Answering Systems, and a Chatbot Interface. These features
                    help you assess student progress, recommend study material, or automatically answer student
                    questions.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* AI Assisted Learning end */}

        {/* Seamless Online Exams */}
        <section id="Seamless-Online-Exams" className="py-5">
          <article className="container py-2">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img
                  src="/images/solutions/test/exam.webp"
                  alt="Online Exams"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/instructor.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Online Exams</h2>
                  <div className="h5 text-black font-weight-normal">
                    The exam tool gives students the ability to study with digital flashcards and prepare with practice
                    exams. It also includes proctoring tools, plagiarism detection, timed exams, and more.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Seamless Online Exams end */}

        {/* Proctoring Tools*/}
        <section id="Proctoring-Tools" className="py-5 bg-80 left">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6 }}>
                <img
                  src="/images/solutions/test/proctoring-tools.webp"
                  alt="Proctoring Tools"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, offset: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/proctoring-tools.svg" alt="Proctoring Tools" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Proctoring Tools</h2>
                  <div className="h5 text-black font-weight-normal">
                    Our proctoring tools ensure that online exams are protected from cheating of any kind. Our platform
                    is embedded with mouse tracking and video streaming features to ensure that no suspicious behavior
                    occurs throughout the course of the exam. Proctors are enabled to intervene as needed to address any
                    issues that may arise.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Proctoring Tools end */}

        {/* AI Exam Creator */}
        <section id="AI-Exam-Creator" className="py-5">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img
                  src="/images/solutions/test/exam-creator.webp"
                  alt="AI Enabled Exam Builder"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/exam-creator.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">AI Enabled Exam Builder</h2>
                  <div className="h5 text-black font-weight-normal">
                    Our AI Enabled Exam Builder helps create or adapt exams at scale. This tool ensures you won't need
                    to create exams from scratch and gives you the option to import exams from third party databases.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* AI Exam Creator end */}

        {/* AI Quiz Difficulty Prediction */}
        <section className="py-5 bg-80 left" id="AI-Quiz-Difficulty-Prediction">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6 }}>
                <img
                  src="/images/solutions/test/quiz.webp"
                  alt="Exams"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, offset: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/difficulty-prediction.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">AI Quiz Difficulty Prediction</h2>
                  <div className="h5 text-black font-weight-normal">
                    This tool helps instructors create exams with consistent levels of difficulty allowing them to focus
                    on the details of managing testing environments and test takers.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* AI Quiz Difficulty Prediction end */}

        {/* call to action */}
        <section className="py-5">
          <article className="container">
            <Row className="justify-content-center">
              <Col lg="10">
                <div className="rounded border border-success p-4 text-center">
                  <span className="d-inline-block mr-md-5 mb-0 h4 font-weight-bold">
                    Interested in more information about our platform?
                  </span>
                  {/* <button className="btn btn-success" onClick={() => this.setState({modalShow: true})}>Request a Demo</button> */}
                  <Link
                    to="/request-for-more-info/"
                    className="btn btn-success mt-3 mt-xl-0"
                    // onClick={() => FormEventAnalytics("TestCenter_Bottom_Request_Demo")}
                    state={{ page: "testcenter" }}
                    id="request--for-more-info__bottom"
                  >
                    REQUEST FOR MORE INFO
                  </Link>
                </div>
              </Col>
            </Row>
          </article>
        </section>
      </Layout>
    )
  }
}

export default TestCenter
